import React, { useEffect } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";

const PressReleases = () => {
  const seo = {
    metaDesc: 'News - Inventiv.org'
  }

  useEffect(() => {
    document?.body.classList.add('no-home');
  }, []);

  return (
    <Layout>
      <SEO title="News - Inventiv.org" canonical='/provisionalbuilder' seo={seo} />
      <div class="sub-banner overview-bgi">
        <div class="container">
          <div class="breadcrumb-area">
            <h1>Free Provisional Patent Writing Software</h1>
          </div>
        </div>
      </div>

      <section class="mt-3 pt-3 pb-3 wow fadeInUp delay-04s">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="elementor-widget-container">
                <p><a href="https://finance.yahoo.com/news/inventiv-develops-free-provisional-patent-110000835.html" target="_blank"
                  rel="noopener">Yahoo Finance!</a></p>
                <p><b>Inventiv Foundation, a 501c3 non-profit, announces the development of free resources and software for
                  inventors. Resources provided at Inventiv.org includes free software on provisional patent application
                  filing and a wealth of how-to videos. Now it’s easy for a business owner to patent a great idea, as the
                  information and resources at Inventiv.org and the United States Patent and Trademark Office Inventor
                  Assistance program have leveled the playing field for inventors.</b></p>
                <p class="canvas-atom canvas-text Mb(1.0em) Mb(0)--sm Mt(0.8em)--sm" data-reactid="13"><span
                  class="xn-location">ZEPHYR COVE, Nev.</span>,&nbsp;<span class="xn-chron">April 4,
                    2020</span>&nbsp;/PRNewswire-PRWeb/ — The&nbsp;<a
                      href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2768915-1&amp;h=126780919&amp;u=http%3A%2F%2Fwww.inventiv.org%2F&amp;a=Inventiv+Foundation+Inc."
                      target="_blank" rel="nofollow noopener noreferrer">Inventiv Foundation Inc.</a>, a non-profit company,
                  announced today the release of informational videos and resources to help inventors learn about the intellectual
                  property system that has long benefited large companies.</p>
                <p class="canvas-atom canvas-text Mb(1.0em) Mb(0)--sm Mt(0.8em)--sm" data-reactid="14">Filing quickly and with high
                  quality is important in the current first-to-file patent system. Throughout history, inventors who first worked
                  on innovative ideas are often not recognized. Rather, those who can raise funding and get to market quicker have
                  often benefited from the original inventor’s hard work. For example, the controversy between&nbsp;<span
                    class="xn-person">Guglielmo Marconi</span>&nbsp;and Nikola Tesla as to who is the true inventor of the radio
                  is well known.</p>
                <p class="canvas-atom canvas-text Mb(1.0em) Mb(0)--sm Mt(0.8em)--sm" data-reactid="15">Nowadays it’s easy for a
                  business owner to patent a great idea, as the information and resources at Inventiv.org and the United States
                  Patent and Trademark Office Inventor Assistance program have leveled the playing field for inventors.</p>
                <p class="canvas-atom canvas-text Mb(1.0em) Mb(0)--sm Mt(0.8em)--sm" data-reactid="16">According to&nbsp;<span
                  class="xn-person">Jack Young</span>, “Inventiv provides great help to an underserved community who could not
                  afford expensive patent lawyers. The videos at&nbsp;<a
                    href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2768915-1&amp;h=2363667197&amp;u=http%3A%2F%2Fwww.inventiv.org%2F&amp;a=http%3A%2F%2Fwww.inventiv.org"
                    target="_blank" rel="nofollow noopener noreferrer">http://www.inventiv.org</a>&nbsp;site teach our
                  innovators a great deal about the benefits of our patent system which is behind America’s prosperity.”</p>
                <p class="canvas-atom canvas-text Mb(1.0em) Mb(0)--sm Mt(0.8em)--sm" data-reactid="17">Resources provided at
                  Inventiv.org includes free software on provisional patent application filing and a wealth of how-to videos such
                  as how to patent an idea or product, what is a patent, how to search patents, how to check for patents, and how
                  to file a patent, among others. More resources will be provided over time. Inventiv’s assistance to the inventor
                  community is made possible through the valuable contributions from volunteer&nbsp;<a
                    href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2768915-1&amp;h=2154491366&amp;u=http%3A%2F%2Fwww.tranassoc.com%2F&amp;a=law+firms"
                    target="_blank" rel="nofollow noopener noreferrer">law firms</a>&nbsp;and&nbsp;<a
                      href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2768915-1&amp;h=1507876622&amp;u=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fbaotran%2F&amp;a=patent+lawyers"
                      target="_blank" rel="nofollow noopener noreferrer">patent lawyers</a>. The free provisional filing
                  software,&nbsp;<a
                    href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2768915-1&amp;h=865641354&amp;u=https%3A%2F%2Fwww.youtube.com%2Fchannel%2FUCttL6pVjAUPGBxVATuZNbcQ&amp;a=video"
                    target="_blank" rel="nofollow noopener noreferrer">video</a>, and other resources are constantly updated by
                  volunteers interested in improving access to our patent system by innovative start-ups, small companies, and
                  individuals. Topics being worked on to answer inventor questions include how to patent an idea, what is a
                  patent, how to patent a product, how to search patents, what is patent law, how to patent, how to check patents,
                  how to file a patent, how to patent an invention, can you patent an idea, how to patent an idea for free, how to
                  get a patent, how to patent an idea or product, how much does a patent cost, how much does it cost to patent an
                  idea, how long does it take to get a patent, what does a patent do, how to apply for a patent, what is a patent
                  for, what does patent protect, who grants patents, why get a patent, how many patents are there,&nbsp;<a
                    href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2768915-1&amp;h=2537600636&amp;u=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dsw8mQYQd6Xw&amp;a=how+to+do+a+patent+search"
                    target="_blank" rel="nofollow noopener noreferrer">how to do a patent search</a>, how do patents work, what
                  can be patented,&nbsp;<a
                    href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2768915-1&amp;h=1601610150&amp;u=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DXMOnDw1_hac&amp;a=can+software+be+patented"
                    target="_blank" rel="nofollow noopener noreferrer">can software be patented</a>, how to invent something,
                  and how much do utility patents cost, among others.</p>
                <p class="canvas-atom canvas-text Mb(1.0em) Mb(0)--sm Mt(0.8em)--sm" data-reactid="18">Inventiv relies on its
                  volunteers and&nbsp;<a
                    href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2768915-1&amp;h=2558334213&amp;u=http%3A%2F%2Fwww.tranassoc.com%2F&amp;a=patent+professionals"
                    target="_blank" rel="nofollow noopener noreferrer">patent professionals</a>&nbsp;who provide it with
                  assistance. If you would like to help inventors, please contact us.</p>
                <p class="canvas-atom canvas-text Mb(1.0em) Mb(0)--sm Mt(0.8em)--sm" data-reactid="19">Follow Us
                  at<br />Facebook:&nbsp;<a
                    href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2768915-1&amp;h=2227884737&amp;u=https%3A%2F%2Fwww.facebook.com%2FInventiv-Foundation-Inc-103864371253299&amp;a=https%3A%2F%2Fwww.facebook.com%2FInventiv-Foundation-Inc-103864371253299"
                    target="_blank"
                    rel="nofollow noopener noreferrer">https://www.facebook.com/Inventiv-Foundation-Inc-103864371253299</a><br />Twitter:&nbsp;<a
                      href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2768915-1&amp;h=990137371&amp;u=https%3A%2F%2Ftwitter.com%2Finventivorg&amp;a=https%3A%2F%2Ftwitter.com%2Finventivorg"
                      target="_blank" rel="nofollow noopener noreferrer">https://twitter.com/inventivorg</a><br />LinkedIn:&nbsp;<a
                        href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2768915-1&amp;h=3192456812&amp;u=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Finventivfoundation&amp;a=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Finventivfoundation"
                        target="_blank" rel="nofollow noopener noreferrer">https://www.linkedin.com/company/inventivfoundation</a>
                </p>
                <p>Inventiv Foundation</p>
                <h3><strong>310 E. Williams Street, Suite 6, Carson City, Nv 89701</strong></h3>
              </div>
            </div>
          </div>
        </div>
      </section >
    </Layout >
  )
};

export default PressReleases;